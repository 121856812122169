import {useAppToast} from '~/stores/appToast';

export interface AccountSecurityDTO {
  newPassword: string;
  oldPassword: string | null;
}

export interface AccountSecurityInfo {
  email: string;
  hasPassword: boolean;
}

export const useAccountSecurityStore = defineStore(
  'accountSecurityStore',
  () => {
    const {showSuccessToast, showApiErrorToast} = useAppToast();

    const securityInfo = ref<AccountSecurityInfo>();

    function setSecurityInfo(value: AccountSecurityInfo) {
      securityInfo.value = value;
    }

    function getSecurityInfo(): AccountSecurityInfo | undefined {
      return securityInfo.value;
    }

    function fetchSecurityInfo() {
      return useGetJobApi('/account/manage/info', {
        method: 'GET',
        onResponse: ({response}) => {
          setSecurityInfo(response._data);
        },
        onResponseError: showApiErrorToast,
      });
    }

    function updateSecurityInfo(info: AccountSecurityDTO) {
      return useGetJobApi('/account/manage/info', {
        method: 'POST',
        body: {
          ...info,
        },
        onResponse: ({response}) => {
          if (response.status === 200) {
            showSuccessToast('Пароль изменен');
          }
        },
        onResponseError: showApiErrorToast,
      });
    }

    function $reset() {
      securityInfo.value = undefined;
    }

    return {
      fetchSecurityInfo,
      updateSecurityInfo,
      getSecurityInfo,
      $reset,
    };
  },
);
